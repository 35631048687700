import * as React from 'react';
import {
  CameraIcon,
  ClipboardListIcon,
  CodeIcon,
  DocumentTextIcon,
  EyeIcon,
  GiftIcon,
} from '@heroicons/react/outline';
import Feature from './Feature';

export default function FeatureSection() {
  return (
    <div className="relative py-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2
          id="features"
          className="text-base font-semibold tracking-wider text-emerald-500 uppercase"
        >
          Consent Done Right
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          We've thought of everything so you don't have to
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Hidden behind the humble checkbox are intelligent features that will make you wonder how
          you slept at night. Whether you're a developer, marketer or compliance officer we've got
          you covered.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <Feature
              title="Privacy First"
              description="No Personally Identifiable Information (PII) is captured. All we require is a unique ID to associate to your customer"
              icon={<EyeIcon className="h-6 w-6 text-white" />}
            />

            <Feature
              title="Pre-built Components"
              description="Save time with headless drop-in replacement components for React (Angular, Vue &amp; Svelte coming soon)"
              icon={<GiftIcon className="h-6 w-6 text-white" />}
            />

            <Feature
              title="Screenshot Capture"
              description="See what the customer agreed to, as they saw it with their own eyes, with our component capture feature of our pre-built components"
              icon={<CameraIcon className="h-6 w-6 text-white" />}
            />

            <Feature
              title="Full Audit Trail"
              description="From initial agreement to updates, all interactions are recorded as immutable events along with device and location data allowing you to see the full story"
              icon={<ClipboardListIcon className="h-6 w-6 text-white" />}
            />

            <Feature
              title="Developer API"
              description="Don't like our components? Integration missing? Need to query from internal systems? Grab what you need from our RESTful API "
              icon={<CodeIcon className="h-6 w-6 text-white" />}
            />

            <Feature
              title="Compliance Content Management"
              description="Mistakes and outdated compliance copy has serious consequences. We empower risk & compliance to manage and maintain it directly so it's always up-to-date"
              icon={<DocumentTextIcon className="h-6 w-6 text-white" />}
              comingSoon
            />
          </div>
        </div>
      </div>
    </div>
  );
}
