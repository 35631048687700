import * as React from 'react';
import { CloudIcon } from '@heroicons/react/outline';

export default function Feature({
  title = 'Amazing Feature',
  description = 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
  comingSoon = false,
  icon,
}) {
  return (
    <div className="pt-6">
      <div className="relative flow-root bg-gray-50 rounded-lg px-6 pb-8">
        {comingSoon && (
          <span className="absolute -top-3 right-3 px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gray-500 rounded-full">
            coming soon
          </span>
        )}
        <div className="-mt-6">
          <div>
            <span className="inline-flex items-center justify-center p-3 bg-emerald-400 rounded-md shadow-lg">
              {icon || <CloudIcon className="h-6 w-6 text-white" aria-hidden="true" />}
            </span>
          </div>
          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{title}</h3>
          <p className="mt-5 text-base text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
}
