import React from 'react';
import { Link } from 'gatsby';
import EarlyAccessForm from './EarlyAccessForm';
import consoleScreenshot from '../images/console-events-detail-screen.png';

export default function HeroSection() {
  return (
    <section className="max-w-7xl mx-auto pt-8 pb-32 px-4 sm:px-6 md:pb-60 lg:pt-8 lg:pb-20">
      <div className="lg:grid lg:grid-cols-2 lg:gap-8">
        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
          <div className="lg:py-24">
            <h1>
              <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Launching soon
              </span>
              <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="block text-gray-900">Your single view of</span>
                <span className="block text-emerald-400">customer consent</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Supercharge your forms to reliably capture customer agreements and consent. Integrate
              with downstream systems to respect your customer's preferences and stay on the right
              side of the law.
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <p className="text-base font-medium text-gray-900">
                Apply for early access to the private beta.
              </p>
              <EarlyAccessForm />
              <p className="mt-3 text-sm text-gray-500">
                Your data is just that. Yours. Read our{' '}
                <Link to="/privacy" className="font-medium text-gray-900 underline">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
            <img
              className="w-full rounded-md shadow-md lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              src={consoleScreenshot}
              alt="Screenshot of Consentrally console"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
