import * as React from 'react';

export default function Button({
  className = '',
  type = 'button',
  disabled = false,
  loading = false,
  loadingText = 'Submitting...',
  backgroundColor = 'bg-gray-800',
  hoverBackgroundColor = 'hover:bg-gray-900',
  disabledBackgroundColor = 'disabled:bg-gray-400',
  textColor = 'text-white',
  focusRingColor = 'focus:ring-emerald-500',
  children,
  ...rest
}) {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={`mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md ${textColor} ${backgroundColor} shadow-sm ${hoverBackgroundColor} ${disabledBackgroundColor} focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingColor} ${className}`}
      {...rest}
    >
      <span className="inline-flex align-middle justify-center">
        {loading ? (
          <>
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 {textColor}"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            {loadingText}
          </>
        ) : (
          children || 'Submit'
        )}
      </span>
    </button>
  );
}
