import React from 'react';
import { LightBulbIcon, SparklesIcon } from '@heroicons/react/outline';
import formStackDiagram from '../images/form-stack-diagram.png';
import profilePic from '../images/binesh.jpeg';
import integrationDiagram from '../images/integration-animated.svg';

export default function ProductSection() {
  return (
    <div id="product" className="relative py-16 overflow-hidden">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-200"
      />
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-emerald-400 to-emerald-600">
                  <LightBulbIcon className="h-6 w-6 text-white" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Ever wondered where all those checkboxes go?
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  We have, and the news isn't good. If you're lucky they're a flag in a database
                  with the date &amp; time recorded. More are simply thrown away and "implied" by
                  the sign up process. Proving customers gave consent, let alone with specific
                  detail, is difficult to impossible.
                </p>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    Requests for information from regulators across financial services have
                    increased dramatically over the last few years...pulling together relevant and
                    accurate data has given rise to operational and administrative difficulties and
                    has been time-consuming.
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img className="h-6 w-6 rounded-full" src={profilePic} alt="profile pic" />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Binesh, Senior Investment Risk Manager
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={formStackDiagram}
                alt="Where does your consent data go?"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-emerald-400 to-emerald-600">
                  <SparklesIcon className="h-6 w-6 text-white" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Have confidence consent is captured consistently across all channels
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Consentrally is designed to integrate with your existing forms and SaaS
                  subscriptions to bring together detailed and audit-friendly records of all your
                  customer consent data.
                </p>
                <p className="mt-4 text-lg text-gray-500">
                  Consent is stored against a customer ID of your choosing for easy lookup through
                  our console. Our developer API enables rich integration and automation of consent
                  driven functions across your business systems.
                </p>
                <div className="mt-6">
                  <a
                    href="#apply"
                    className="inline-flex bg-gradient-to-r from-emerald-400 to-emerald-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-emerald-700 hover:to-emerald-700"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <object
                type="image/svg+xml"
                data={integrationDiagram}
                aria-label="Consentrally integration diagram"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
