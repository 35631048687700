import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from './Button';
import Dialog from './Dialog';
import * as hubspot from '../services/hubspot';

const HUBSPOT_FORM_ID = 'bf997a1f-75c5-4575-a4c5-f3f0fc391ff0';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
});

export default function EarlyAccessForm({ onSubmit }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  async function handleSubmit(values, { resetForm, setFieldError }) {
    const fields = [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: values.email,
      },
    ];
    const response = await hubspot.submitForm(HUBSPOT_FORM_ID, fields);
    window.analytics.track('EarlyAccessForm Submitted', { email: values.email });

    if (response.errors) {
      const [error] = response.errors;
      if (error.errorType === 'INVALID_EMAIL') {
        setFieldError('email', 'Please enter a valid email address');
      } else {
        window.alert(error.message);
      }

      window.analytics.track('EarlyAccessForm Error', { message: error.message });
    } else {
      setMessage(<div dangerouslySetInnerHTML={{ __html: response.inlineMessage }} />);
      setOpen(true);
      resetForm();

      window.analytics.track('EarlyAccessForm Success');
      window.analytics.identify(values.email);
    }
  }

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="mt-3">
            <div className="flex">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <Field
                id="email"
                type="email"
                name="email"
                className={` block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:flex-1 border-gray-300 ${
                  errors.email && touched.email && 'border-pink-600'
                }`}
                placeholder="Enter your email"
              />
              <Button
                type="submit"
                loading={isSubmitting}
                className="sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
              >
                Apply Now
              </Button>
            </div>
            <ErrorMessage name="email" component="div" className="mt-2 text-pink-600" />
          </Form>
        )}
      </Formik>
      <Dialog
        title="Application submitted"
        message={message}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
