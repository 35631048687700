import Cookies from 'js-cookie';

const PORTAL_ID = process.env.GATSBY_HUBSPOT_PORTAL_ID;
const BASE_URL = process.env.GATSBY_HUBSPOT_FORM_SUBMIT_BASE_URL;

export async function submitForm(formId, fields) {
  const url = `${BASE_URL}/${PORTAL_ID}/${formId}`;

  const timestamp = +new Date();

  const payload = {
    submittedAt: `${timestamp}`,
    fields,
    context: {
      hutk: Cookies.get('hubspotutk'),
      pageUri: `${window.location.hostname}${window.location.pathname}`,
      pageName: document.title,
    },
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response.json();
}
