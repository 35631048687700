import * as React from 'react';
import CallToActionSection from '../components/CallToActionSection';
import FeatureSection from '../components/FeatureSection';
import HeroSection from '../components/HeroSection';
import ProductSection from '../components/ProductSection';
import DefaultLayout from '../layouts/DefaultLayout';

// markup
const IndexPage = () => {
  return (
    <DefaultLayout title="Consentrally">
      <HeroSection />
      <ProductSection />
      <FeatureSection />
      <CallToActionSection />
    </DefaultLayout>
  );
};

export default IndexPage;
